import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised,
  whiteColor
} from "assets/jss/material-kit-pro-react.jsx";

import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const features = function(theme) {
  return {
    container,
    mlAuto,
    mrAuto,
    title,
    main,
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...modalStyle(theme),
    mainRaised,
    ...sectionCards,
    description,
    team: {
      padding: `80px 0px`
    },
    header: {
      marginTop: `5%`,
      marginBottom: `5%`
    },
    gridContainer: {},
    gridItem: {},
    textCenter: {
      textAlign: `center`
    },
    phoneContainer: {
      "& img": {
        width: `100%`
      }
    },
    infoArea: {
      maxWidth: `none`,
      margin: `0 auto`,
      padding: `10px 0 0px`
    },
    buttonfixport: {
      [theme.breakpoints.down(`lg`)]: {
        justifyContent: `center`,
        marginLeft: `20px`
      }
    },
    centerfixportability: {
      marginLeft: `10%`,
      [theme.breakpoints.down(`md`)]: {
        marginLeft: `0%`
      }
    },
    infoArea5: {},
    customCard: {
      boxShadow: `none`,
      border: `1px solid #eee`,
      "&:hover": {
        boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px - 5px rgba(255, 255, 255, 0.4)`
        // border: "none"
      }
    },
    h1title:{
    ...title,
    fontSize:'38px'
    },
    imagesContainer: {
      marginTop: `11%`,
      marginBottom: `5%`,
      display: `grid`,
      paddingTop: `8%`,
      paddingBottom: `5%`,
      "@media (max-width:1024px)": {
        marginTop: `15%`,
        marginBottom: `-7%`
      }
    },
    talkToDoctor: {
      marginLeft: `auto`,
      marginRight: `auto`,
      "@media (max-width: 640px)": {
        width: `100%`
      }
    },
    lineHeight: {
      lineHeight: '0px !important',
      '@media (max-width: 640px)': {
        lineHeight: `20px !important`,
      },
    },
    bgBar: {
      height: `12px`,
      borderRadius: `9px`,
      backgroundColor: `#eeeeee !important`
    },
    low: {
      width: `20%`,
      [theme.breakpoints.down(`sm`)]: {
        width: `30%`
      },
      backgroundColor: `#8bc34a !important`
    },
    moderate: {
      width: `20%`,
      [theme.breakpoints.down(`sm`)]: {
        width: `30%`
      },
      backgroundColor: `#ffc107 !important`
    },
    high: {
      width: `20%`,
      [theme.breakpoints.down(`sm`)]: {
        width: `30%`
      },
      backgroundColor: `#f44336 !important`
    },
    inactive: {
      width: `20%`,
      [theme.breakpoints.down(`sm`)]: {
        width: `30%`
      },
      backgroundColor: `#eeeeee !important`,
      color: `black !important`
    },
    img: {
      width: `30%`,
      [theme.breakpoints.down(`sm`)]: {
        width: `70%`
      }
    },
    iconTop: {
      top: `83%`,
      [theme.breakpoints.down(`md`)]: {
        top: `85%`
      },
      [theme.breakpoints.down(`sm`)]: {
        top: `86%`
      },
      "@media (max-width : 736px)": {
        top: `87%`
      },
      [theme.breakpoints.down(`xs`)]: {
        top: `87%`
      }
    },
    googlePlay: {
      width: `5%`,
      height: `4%`,
      left: `44%`,
      [theme.breakpoints.down(`md`)]: {
        width: `7%`,
        left: `42%`
      },
      [theme.breakpoints.down(`sm`)]: {
        left: `39%`,
        width: `10%`
      },
      "@media (max-width : 823px)": {
        left: `40%`,
        width: `9%`
      },
      "@media (max-width : 736px)": {
        left: `42%`
      },
      "@media (max-width : 667px)": {
        left: `41%`,
        width: `11%`
      },
      "@media (max-width : 640px)": {
        left: `38%`
      },
      [theme.breakpoints.down(`xs`)]: {
        left: `37%`,
        width: `12%`
      }
    },
    appStore: {
      width: `6%`,
      height: `4%`,
      left: `50%`,
      [theme.breakpoints.down(`md`)]: {
        width: `8%`
      },
      [theme.breakpoints.down(`sm`)]: {
        width: `11%`
      },
      "@media (max-width : 823px)": {
        width: `10%`
      },
      "@media (max-width : 736px)": {
        left: `53%`
      },
      "@media (max-width : 667px)": {
        left: `54%`
      },
      "@media (max-width : 640px)": {
        left: `50%`,
        width: `12%`
      },
      [theme.breakpoints.down(`xs`)]: {
        left: `50%`,
        width: `13%`
      }
    },
    thankYouImage:{
      backgroundColor: `#f6f5f3`,
      "& img":{
        margin:`auto`,
        display:`block`,
        [theme.breakpoints.down(`md`)]:{
          width:`inherit`
        }
      }
    },
    messageContainer:{
      backgroundColor: whiteColor,
      marginTop: `-1%`,
      marginBottom: `-1%`,
      padding: `7%`,
      textAlign: `center`
    },
    thankYouTitle:{
      color: `#77663e`,
      fontSize: `28px`,
      textAlign: `center`,
      fontWeight: 500,
      lineHeight: `28px`
    },
    thankYouBody:{
      color: `#c99f36`,
      fontSize: `14px`,
      textAlign: `center`,
      fontWeight: 500,
      lineHeight: `18px`,
      margin: `8px auto`,
      paddingBottom: `30px`
    }
  };
};

export default features;

import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table";
import Layout from "ps-components/Layout/Layout.jsx";
import Card from "components/Card/Card.jsx";
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";

const regulationsOnAppointment = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var clrImage = Images[1];
  var tableHead = [
    `Agent Name`,
    `PAN Number`,
    `Agency Code`,
    `Office`,
    `Date of Cancellation`
  ];
  var tableRows = data.allStrapiContacts.edges.map(document => {
    var temp = [];
    temp.push(document.node.name);
    var first = document.node.PANnumber.substring(0, 2);
    var last = document.node.PANnumber.substring(
      document.node.PANnumber.length - 2
    );
    var mask = document.node.PANnumber.substring(
      2,
      document.node.PANnumber.length - 2
    ).replace(/[0-9A-Z]/g, `*`);
    temp.push(first + mask + last);
    temp.push(document.node.agencyCode);
    temp.push(document.node.office);
    temp.push(document.node.dateOfCancellation);
    return temp;
  });
  var content = (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={10}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.textCenter
        )}
      >
        <Card>
          <Table striped tableHead={tableHead} tableData={tableRows} />
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <Layout
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>IRDAI Regulations on Appointment - StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/irdai-regulations-appointment"
          />
          <meta
            name="title"
            content="IRDAI Regulations on Appointment - StarHealth.in"
          />
          <meta
            name="twitter:title"
            content="IRDAI Regulations on Appointment - StarHealth.in"
          />
          <meta
            name="description"
            content="List of agents whose appointment has been cancelled. Refer the agents details like Agent Name, PAN Number, Agency Code, Office Address and Date of Cancellation."
          />
          <meta property="og:title" content="IRDAI Regulations on Appointment - StarHealth.in" />
          <meta property="og:description" content="List of agents whose appointment has been cancelled. Refer the agents details like Agent Name, PAN Number, Agency Code, Office Address and Date of Cancellation." />
          <meta property="og:url" content="https://www.starhealth.in/irdai-regulations-appointment"/>
          <meta name="IRDAI Regulations on Appointment - StarHealth.in" />
          <meta name="twitter:description" content="List of agents whose appointment has been cancelled. Refer the agents details like Agent Name, PAN Number, Agency Code, Office Address and Date of Cancellation." />
          <meta property="twitter:url" content="https://www.starhealth.in/irdai-regulations-appointment" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100, padding: `70px 0px` }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={10}
                sm={10}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.title}>
                  IRDAI Regulations on Appointment
                </h2>
                <h3 style={{ textAlign: `left` }}>
                  List of agents whose appointment has been cancelled
                </h3>
              </GridItem>
              <GridItem>{content}</GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
regulationsOnAppointment.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(customStaticPageStyle)(regulationsOnAppointment);

export const aboutUsQuery = graphql`
  query regulationsOnAppointment {
    allStrapiContacts(
      filter: { Unit: { eq: "RegulationsOnAppointment" } }
      sort: { fields: dateOfCancellation, order: DESC }
    ) {
      edges {
        node {
          id
          name
          PANnumber
          agencyCode
          Unit
          dateOfCancellation(formatString: "DD-MM-YYYY")
          office
        }
      }
    }
    allStrapiImages(
      filter: { section: { category: { eq: "irda_regulations_appointment" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
